<template>
    <div>
        <p class="p-title">各清洁屋采集情况</p>
        <a-table :columns="columns" :data-source="houseData">
            <span slot="recyclablePercent" slot-scope="text,record">
                {{record.recyclablePercent}}%
            </span>
            <span slot="kitchenWastePercent" slot-scope="text,record">
                {{record.kitchenWastePercent}}%
            </span>
            <span slot="action" slot-scope="text, record, index">
                <a @click="viewDetail(record, index)">查看详情</a>
            </span>
        </a-table>
        <house ref="house"/>
    </div>
</template>

<script>
import house from './house'
export default {
  components: { house },
  name: 'overviewOfStreetCollect',
    props: {
        houseData: {
            type: Array,
            default: []
        },
        houseDataBak: {
            type: Array,
            default: []
        }
    },
    data() {
        return {
            columns: [
                {
                    title: '清洁屋',
                    dataIndex: 'pointName',
                    key: 'pointName'
                },
                {
                    title: '采集总量',
                    dataIndex: 'total',
                    key: 'total'
                },
                {
                    title: '厨余垃圾',
                    dataIndex: 'kitchenWaste',
                    key: 'kitchenWaste'
                },
                {
                    title: '其他垃圾',
                    dataIndex: 'other',
                    key: 'other'
                },
                {
                    title: '可回收物',
                    dataIndex: 'recyclable',
                    key: 'recyclable'
                },
                {
                    title: '有害垃圾',
                    dataIndex: 'harmful',
                    key: 'harmfulPercent'
                },
                {
                    title: '厨余垃圾分出率',
                    dataIndex: 'kitchenWastePercent',
                    key: 'kitchenWastePercent',
                    scopedSlots: { customRender: 'kitchenWastePercent' },
                },
                {
                    title: '其他垃圾分出率',
                    dataIndex: 'otherPercent',
                    key: 'otherPercent',
                    scopedSlots: { customRender: 'otherPercent' },
                },
                {
                    title: '操作',
                    dataIndex: 'action',
                    scopedSlots: { customRender: 'action' },
                }
            ],
        }
    },
    methods: {
        viewDetail(record, index){
            this.houseDataBak.forEach((item, index2) => {
                if(index == index2 ) {
                    record = item
                }
            })
            this.$refs.house.showModal(record)
        },

    }
}
</script>
<style lang="scss">
  .p-title{
    font-weight: bolder;
    font-size: larger;
  }
</style>