<template>
    <div class="content">
        <a-spin :spinning="centerLoading">
            <a-descriptions style="padding: 10px;" title="小区信息">
                <a-descriptions-item label="负责人">
                    {{ centerInfo.personInCharge }}
                </a-descriptions-item>
                <a-descriptions-item label="创建时间">
                    {{ centerInfo.constructionTime | filterTime }}
                </a-descriptions-item>
                <a-descriptions-item label="采集点数量">
                    {{ centerInfo.pointNumbers }}
                </a-descriptions-item>
                <a-descriptions-item label="住户数">
                    {{ centerInfo.households }}
                </a-descriptions-item>
                <a-descriptions-item label="设备数量">
                    1
                </a-descriptions-item>
                <a-descriptions-item label="首次采集时间">
                    {{ centerInfo.firstCollectTime | filterTime }}
                </a-descriptions-item>
                <a-descriptions-item label="所属行政">
                    {{ centerInfo.areaName }} -{{ centerInfo.streetName }}
                </a-descriptions-item>
                <a-descriptions-item label="定位信息">
                    {{ centerInfo.address }}
                </a-descriptions-item>
            </a-descriptions>
        </a-spin>
        <a-space direction="vertical" style="width: 100%">
            <a-card>
                <a-row>
                    <a-form layout="inline">
                        <a-form-item label="查询时间">
                            <a-range-picker v-model="params.time" @change="onChange"/>
                        </a-form-item>
                    </a-form>
                </a-row>

                <a-row :gutter="16" style="padding-top: 24px;">
                    <a-spin :spinning="loading">
                        <p class="p-title">数据采集总量</p>
                        <a-col v-for="(item, index) in typeList" :key="`type-${index}`" :span="6">
                            <trash-collect-amount-card :typeData="item"/>
                        </a-col>
                    </a-spin>
                </a-row>

                <a-row style="padding-top: 24px;">
                    <a-spin :spinning="loading">
                        <overview-of-street-collect :houseData="houseData" :houseDataBak="houseDataBak"/>
                    </a-spin>
                </a-row>
            </a-card>

            <a-card>
                <a-row :gutter="16">
                    <a-col :span="24">
                        <data-alarm ref="dataAlarm"/>
                    </a-col>
                </a-row>
            </a-card>

            <a-row :gutter="16">
                <a-col :span="12">
                    <heat-map ref="heatMap"/>
                </a-col>

                <a-col :span="12">
                    <curve-line ref="curveLine"/>
                </a-col>
            </a-row>
        </a-space>
    </div>
</template>
<script>
import moment from 'moment'
import curveLine from './component/curveLine'
import heatMap from './component/heatMap'
import dataAlarm from './component/dataAlarm'
import overviewOfStreetCollect from './component/overviewOfStreetCollect'
import {queryCenterHouseApi, queryCenterInfoApi, queryCenterTypeApi} from '@/api/home'
import trashCollectAmountCard from './component/trashCollectAmountCard'

export default {
    components: {trashCollectAmountCard, overviewOfStreetCollect, dataAlarm, heatMap, curveLine},
    data() {
        return {
            loading: false,
            centerLoading: false,
            centerInfo: {},
            params: {
                time: [moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'), moment().startOf('month').format("YYYY-MM-DD")]
            },
            typeList: [],
            houseData: [],
            houseDataBak: []
        }
    },
    mounted() {
        this.queryCenterInfo()
        this.queryCenterType()
        this.queryCenterHouse()
        this.$refs.dataAlarm.queryCenterAlarm(['', ''])
        this.$refs.heatMap.queryWeightHeatmap()
        this.$refs.curveLine.queryWeightLinemap()
    },
    filters: {
        filterTime(val) {
            return moment(val).format('YYYY-MM-DD HH:mm:ss') === 'Invalid date' ? '' : moment(val).format('YYYY-MM-DD HH:mm:ss')
        }
    },
    methods: {
        queryCenterInfo() {
            this.centerLoading = true
            queryCenterInfoApi({}, this.$route.query.moduleCode).then(res => {
                this.centerInfo = res.result
                this.centerLoading = false
            })
        },
        onChange(e) {
            this.params.time = e
            this.params.startTime = e[0]
            this.params.endTime = e[1]
            this.queryCenterHouse()
            this.queryCenterType()
        },
        queryCenterHouse() {
            this.params.startTime = this.params.time[0]
            this.params.endTime = this.params.time[1]
            queryCenterHouseApi({...this.params}, this.$route.query.moduleCode).then(res => {
                this.houseData = res.result
                this.houseDataBak = res.result
            })
        },
        queryCenterType() {
            this.loading = true
            this.params.startTime = this.params.time[0]
            this.params.endTime = this.params.time[1]
            queryCenterTypeApi({...this.params}, this.$route.query.moduleCode).then(res => {
                this.typeList = res.result.typeList
                this.loading = false
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.content {
  background-color: white;
  padding: 15px;

  .p-title {
    font-weight: bolder;
    font-size: larger;
  }
}
</style>
