<template>
    <a-card :bordered="false">
        <template slot="title">
        <a-row align="middle" justify="space-between" type="flex">
            <a-col :span="4">
            <span style="font-weight: bolder; font-size: 20px;">热力图</span>
            </a-col>
            <a-col :span="12">
            <a-form layout="inline">
                <a-form-item label="">
                <a-range-picker v-model="query.time" :allowClear="false"
                                :disabled-date="disabledDate"
                                @calendarChange="calendarChange"
                                @change="handleQueryDateRangeChange" @openChange="handleOpenChange"/>
                </a-form-item>
            </a-form>
            </a-col>
        </a-row>
        </template>

        <a-spin :spinning="loading">
        <div id="homeHeatmap" style="height: 300px;"></div>
        </a-spin>
  </a-card>
</template>

<script>
import { getPreviewDate} from "@/utils/time";
import moment from "moment";
import {Heatmap} from '@antv/g2plot';
import { queryCenterHeatMapApi } from '@/api/home'
export default {
    data() {
        return{
            loading: false,
            query: {
                time: []
            },
            heatMapChart: null
        }
    },
    methods: {
        disabledDate(current) {
            if (this.query.time[0])
                return current && (current <= this.query.time[0].clone().subtract(10, 'days') || current >= this.query.time[0].clone().add(10, 'days'));
        },
        calendarChange(dates) {
            this.query.time = dates
        },
        handleOpenChange() {
            this.query.time = []
        },
        handleQueryDateRangeChange() {
            this.queryWeightHeatmap()
        },
        queryWeightHeatmap() {
            this.loading = true
            if(this.query.time.length > 0) {
                this.query.startTime = this.query.time[0]
                this.query.endTime = this.query.time[1]
            } else {
                this.query.time = getPreviewDate(10, 0)
            }
            queryCenterHeatMapApi({...this.query}).then(res => {
                res.result.forEach(item => {
                    item.weight = item.weight.toFixed(2)
                })
                if(this.heatMapChart) {
                    this.heatMapChart.update({
                        data: res.result
                    })
                    this.loading = false
                    return
                }

                this.heatMapChart = new Heatmap(document.getElementById('homeHeatmap'), {
                    autoFit: true,
                    data: res.result,
                    xField: 'date',
                    yField: 'hourSort',
                    colorField: 'weight',
                    sizeField: 'weight',
                    color: ['#6495ED', '#e9a864', '#BE002F'],
                    meta: {
                    'date': {
                        alias: '日期',
                        type: 'cat',
                    },
                    'weight': {
                        alias: '重量',
                        nice: true,
                    },
                    'hourSort': {
                        alias: '时间',
                        type: 'cat',
                        values: ['6:00-7:00', '7:00-8:00', '8:00-9:00', '9:00-10:00', '16:00-17:00', '17:00-18:00', '18:00-19:00', '19:00-20:00'],
                    }
                    },
                    label: {
                        style: {
                        fill: '#fff',
                        shadowBlur: 0,
                        shadowColor: 'rgba(0, 0, 0, .45)',
                        },
                    },
                });
                this.heatMapChart.render();
                this.loading = false
            })
        }
    }
}
</script>